<template>
	<div class="main--wrapper homepage">
		<section class="page--wrapper">
			<div class="container">
				<!-- <Headvisual
					:itemSlides="content.colPos1"
				/> -->
				<!-- <div v-if="breadcrumbs" class="breadcrumbs d-none d-lg-block">
					<breadcrumbs
						:links="breadcrumbs"
					/>
				</div> -->
					<ce-renderer
						:content="content.colPos0"
					/>
			</div>
		</section>
	</div>
</template>

<script>
import BeDefault from '~typo3/layouts/backend/BeDefault';
// import Headvisual from '~/components/organisms/hero/Headvisual';
import Breadcrumbs from '~/components/molecules/navigation/Breadcrumbs'
import { mapState } from 'vuex';
export default {
	functional: false,
	extends: BeDefault,
	components: { Breadcrumbs },
		computed: {
		...mapState({
			breadcrumbs: (state) => {
				return state.typo3.page.page ? state.typo3.page.page.breadcrumbs : [];
			}
		}),
	},
}
</script>

<style lang="scss">

	.main--wrapper.homepage  {
		.page--wrapper {
			margin: 0;
		}
	}

</style>
