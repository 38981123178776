<template>
	<div class="main--wrapper homepage">
		<section class="page--wrapper">
			<div
				class="container"
				v-if="isHeadvisualStaticVideo.length > 0"
			>
				<Herovideo />
					<ce-renderer
						:content="content.colPos0"
					/>
			</div>
			<div
				class="container"
				v-else
			>
				<Headvisual
					:itemSlides="content.colPos1"
				/>
					<ce-renderer
						:content="content.colPos0"
					/>
			</div>
		</section>
	</div>
</template>

<script>
import BeDefault from '~typo3/layouts/backend/BeDefault';
import Headvisual from '~/components/organisms/hero/Headvisual';
import Herovideo from '~/components/organisms/hero/Herovideo';
import { mapState } from 'vuex';
export default {
	functional: false,
	extends: BeDefault,
	components: {
		Headvisual,
		Herovideo
	},
	computed: {
		...mapState({
			isHeadvisualStaticVideo: (state) => {
				return state.typo3.page.page.resources.media;
			},
		}),
	}
}
</script>

<style lang="scss">

	.main--wrapper.homepage  {
		.page--wrapper {
			margin: 0;
		}
	}

</style>
