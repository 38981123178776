<template>
	<transition name="fadeout" mode="out-in">
		<full-page
			:options="fullPageOptions"
			id="fullpage"
			v-if="hasSections"
			ref="fullpage"
		>
			<ActualContent />
		</full-page>
		<ActualContent v-else />
	</transition>
</template>

<script>
// @INFO addRemovedHook for delay fullpage's destroy method
import addRemovedHook from 'vue-removed-hook-mixin'

import { mapState } from 'vuex'
import ActualContent from '~/layouts/ActualContent'

export default {
	name: 'Default',
	components: {
		ActualContent,
	},
	mixins: [addRemovedHook],
	removed() {
		this.$refs.fullpage
	},
	computed: {
		countSectionsInSectionNavigation() {
			let count = 0

			if (this.backendLayout === 'HeroImage') {
				count++
			}

			if (this?.pageContent?.colPos0?.length > 0) {
				for (const element of this.pageContent?.colPos0) {
					if (
						element?.type === 'structured_content' &&
						element?.content?.structure?.layout ===
							'highlight_box' &&
						element?.content?.structure?.rows[0]?.columns[0]?.config
							?.includeInSectionNavigation === true
					) {
						count++
					}
				}
			}
			return count
		},
		hasSections() {
			return this.countSectionsInSectionNavigation >= 2
		},
		fullPageOptions() {
			return {
				menu: '#menu',
				easing: 'easeInOutCubic',
				scrollingSpeed: 900,
				fitToSectionDelay: 1000,
				dragAndMove: true,
				scrollBar: true,
				navigation: this.hasSections,
				slidesNavigation: true,
				offsetSections: true,
				autoScrolling: false,
				fitToSection: false,
				keyboardScrolling: true,
				licenseKey: '259FCA9F-E0AA43B7-BBB04D2A-F83BE95D',
				fixedElements: '.page-footer',
				sectionSelector: '.fp-section',
			}
		},
		...mapState({
			backendLayout: (state) => {
				return (
					state.typo3?.page?.page?.appearance?.backendLayout ||
					'Default'
				)
			},
			pageContent: (state) => {
				return state.typo3?.page?.content || {}
			},
			navMain: (state) => {
				return state.typo3?.initial?.navigation || []
			},
		}),
	},
	mounted() {
	window.addEventListener("ucEvent", this.handleUcEvent);
	if (process.client) {
		const script = document.createElement('script')
		script.text = `
		(function(w,d,s,l,i){
			var g=w[l] ? '&s='+w[l]:'', f=d.getElementsByTagName(s)[0], j=d.createElement(s);
			j.async=true;
			j.src='https://ldgnrtn.com/stm.js?id='+i+g;
			j.referrerPolicy='no-referrer-when-downgrade';
			f.parentNode.insertBefore(j,f);
		})(window,document,'script','name','b8V6j3s8C3J0')
		`;
		document.head.appendChild(script)
	}
  },
  beforeDestroy() {
    window.removeEventListener("ucEvent", this.handleUcEvent);
  },
  methods: {
    handleUcEvent(e) {
      if (e.detail && e.detail.event == "consent_status") {
        const ucAnalyticsService = "Google Analytics";
        const consentGranted = e.detail.hasOwnProperty(ucAnalyticsService)
          ? e.detail[ucAnalyticsService]
          : false;

        this.updateGtm(consentGranted);
      }
    },
    updateGtm(enabled) {
      if (enabled) {
        this.$gtm.init();
      } else {
        this.$gtm.disable();
      }
    },
  },
}
</script>

<style lang="scss">
$fadeout-animation-duration: 0.5s !default;

.fadeout-enter-active,
.fadeout-leave-active {
	transition: opacity $fadeout-animation-duration;
}
.fadeout-enter,
.fadeout-leave-active {
	opacity: 0;
}
</style>
