import { mapState } from 'vuex';

export default {
    name: 'jobApplicationMixin',

    data() {
		return {
			date: new Date(),
			formValues: {},
			country: [
				{
					value: 'DE',
					id: 'deutschland',
					label: 'Deutschland'
				},
				{
					value: 'BE',
					id: 'belgien',
					label: 'Belgien'
				},
				{
					value: 'DK',
					id: 'daenemark',
					label: 'Dänemark'
				},
				{
					value: 'FR',
					id: 'frankreich',
					label: 'Frankreich'
				},
				{
					value: 'GB',
					id: 'grossbritannien',
					label: 'Grossbritannien'
				},
				{
					value: 'IT',
					id: 'italien',
					label: 'Italien'
				},
				{
					value: 'LU',
					id: 'luxemburg',
					label: 'Luxemburg'
				},
				{
					value: 'NL',
					id: 'niederlande',
					label: 'Niederlande'
				},
				{
					value: 'AT',
					id: 'oesterreich',
					label: 'Österreich'
				},
				{
					value: 'PL',
					id: 'polen',
					label: 'Polen'
				},
				{
					value: 'PT',
					id: 'portugal',
					label: 'Portugal'
				},
				{
					value: 'CH',
					id: 'schweiz',
					label: 'Schweiz'
				},
				{
					value: 'ES',
					id: 'spanien',
					label: 'Spanien'
				},
				{
					value: 'TR',
					id: 'tuerkei',
					label: 'Türkei'
				},
    		],
		}
	},

    computed: {
		...mapState(['selectedJobOffer', 'datajobs'])
	},

    methods: {
      async convertToBase64(file) {
				return new Promise((resolve, reject) => {
					let fileReader = new FileReader();
					fileReader.onload = function(fileLoadedEvent) {
						const binaryData = fileLoadedEvent.target.result;
						const base64 = window.btoa(binaryData);
						resolve(base64);
					};

					fileReader.readAsBinaryString(file);
				})
			},

			convertAttachments(fileList) {
				if (fileList) {
					return Promise.all(fileList.files.map(async fileData => {
							const base64File = await this.convertToBase64(fileData.file);
							return {
									name: fileData.name,
									mimetype: 'application/pdf',
									document: base64File
							}
					}));
				}
				return [];
			}
	}
}
