<template>
	<div>
		<be-dynamic
			v-if="error.ssr"
			:page="error.ssr.pageContent.page"
			:content="error.ssr.pageContent.content"
			:type="error.ssr.backendLayout"
		/>
		<!-- <div class="mt-0 page--wrapper">
			<div class="container text-center">
				<h1>ERROR PAGE {{ error.statusCode }}</h1>
			</div>
		</div> -->
	</div>
</template>
<script>
export default {
	props: {
		error: {
			type: Object,
			default: () => {}
		}
	}
}
</script>
