<template>
	<div class="default-page">

		<div
			v-if="isHeadvisualStaticImg.length > 0"
			class="is--headvisual-static-img"
		>
			<HeadvisualStatic>
				<img
					:src="isHeadvisualStaticImg[0].publicUrl"
					:alt="isHeadvisualStaticImg[0].alternative"
				/>
			</HeadvisualStatic>

			<div
				v-if="propertiesTitle && backendLayout === 'Jobmarket'"
				class="is--headvisual-static-img-content-wrapper"
			>
				<div class="container">
					<span class="h1">
						{{ propertiesTitle }}
					</span>
				</div>
			</div>

		</div>

	<section class="page--wrapper">

		<div class="container">
			<div v-if="breadcrumbs" class="breadcrumbs d-none d-lg-block">
				<breadcrumbs
					:links="breadcrumbs"
				/>
			</div>

			<ce-renderer
				:content="content.colPos0"
			/>
		</div>

	</section>

	</div>
</template>

<script>
import BeDefault from '~typo3/layouts/backend/BeDefault'
import HeadvisualStatic from '~/components/organisms/hero/HeadvisualStatic'
import Breadcrumbs from '~/components/molecules/navigation/Breadcrumbs'
import { mapState } from 'vuex';
export default {
	functional: false,
	extends: BeDefault,
	components: { HeadvisualStatic, Breadcrumbs },
	computed: {
		...mapState({
			pageTitle: (state) => {
				return state.typo3.page.page.title;
			},
			propertiesTitle: (state) => {
				return state.typo3.page.page.resources.media[0].properties.title;
			},
			propertiesSubtitle: (state) => {
				return state.typo3.page.page.meta;
			},
			backendLayout: (state) => {
				return state.typo3?.page?.page?.appearance?.backendLayout;
			},
			alternativHeadline: (state) => {
				return state.typo3.page.page.altTitle;
			},
			propertiesSubtitle: (state) => {
				return state.typo3.page.page.meta.subtitle;
			},
			isHeadvisualStaticImg: (state) => {
				return state.typo3.page.page.resources.media;
			},
			breadcrumbs: (state) => {
				return state.typo3.page.page ? state.typo3.page.page.breadcrumbs : [];
			}
		}),
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.page--wrapper {
	h1.page-title {

		margin-top: rem(50);
		margin-bottom: rem(50);

		@include media-breakpoint-down(md) {
			margin-top: rem(30);
		}
	}
}
</style>
