<template>
	<div class="main--wrapper">
		<PageHeader>
			<template v-slot:logo>
				<nav-link
					:to="rootPage.link"
					class="navbar-brand"
					alt="Homepage"
					title="Homepage"
				>
					<Logo />
				</nav-link>
			</template>
			<template v-slot:navigation>
				<NavigationMain :links="navMain" />
				<Search />
				<NavigationHandle />
			</template>
		</PageHeader>

		<div class="nuxt-content">
			<nuxt />
		</div>

		<footer>
			<PageFooter />
		</footer>
		<div
			class="sticky-buttons"
			:class="{ 'is-hidden-buttons': !showStickyButtons }"
		>
			<Launcher />
		</div>

		<!-- <BreakpointHelper />  -->
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Logo from '~/components/atoms/images/Logo'
import NavigationMain from '~/components/molecules/navigation/NavigationMain'
import NavigationHandle from '~/components/molecules/navigation/NavigationHandle'
import Search from '~/components/organisms/kesearch/SearchHeader'
import PageHeader from '~/components/organisms/global/PageHeader'
import PageFooter from '~/components/organisms/global/PageFooter'
import Launcher from '~/chatbot/Launcher.vue'
import BreakpointHelper from '~/components/molecules/utilities/BreakpointHelper'

export default {
	name: 'ActualContent',
	components: {
		PageHeader,
		Logo,
		NavigationMain,
		NavigationHandle,
		Search,
		PageFooter,
		Launcher,
		BreakpointHelper,
	},
	data() {
		return {
			showStickyButtons: true,
			lastScrollPosition: 0,
			scrollOffset: 40,
		}
	},
	computed: {
		...mapState({
			navMain: (state) => {
				return state.typo3?.initial?.navigation || []
			},
			rootPage: (state) => {
				const rootpage = state.typo3?.initial?.rootpage || []
				return (
					rootpage.length > 0?.rootpage || {
						title: '',
						link: '/',
					}
				)
			},
			backendLayout: (state) => {
				return (
					state.typo3?.page?.page?.appearance?.backendLayout ||
					'Default'
				)
			},
			pageContent: (state) => {
				return state.typo3?.page?.content || {}
			},
		}),
	},
	mounted() {
		this.lastScrollPosition = window.pageYOffset
		window.addEventListener('scroll', this.onScroll)
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll)
	},
	methods: {
		onScroll() {
			if (window.pageYOffset < 0) {
				return
			}
			if (
				Math.abs(window.pageYOffset - this.lastScrollPosition) <
				this.scrollOffset
			) {
				return
			}
			this.showStickyButtons =
				window.pageYOffset < this.lastScrollPosition
			this.lastScrollPosition = window.pageYOffset
		},
	},
	mounted() {
		this.$nextTick(() => {
			this.$nuxt.$loading.start()
			setTimeout(() => this.$nuxt.$loading.finish(), 1000)
		})
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.main--wrapper.homepage {
	.page--wrapper {
		margin: 0;
	}
}

@include media-breakpoint-down(sm) {
	.sticky-buttons {
		opacity: 1;
		z-index: 6;
		transition: opacity 0.5s ease-in-out;
	}

	.sticky-buttons.is-hidden-buttons {
		opacity: 0;
		transition: opacity 0.8s ease-in-out;
	}

	html[modal-open='open'],
	body.false {
		.sticky-buttons.is-hidden-buttons {
			opacity: 1;
		}
	}
}
</style>
